import React, { useMemo } from 'react';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

const Hexagon = ({ className, animate, transition, ...properties }) => {
    const gradientId = useMemo(() => `hexagon-gradient-${Math.random().toString(36).substr(2, 9)}`, []);

    return (
        <motion.div className={twMerge('w-full h-full', className)} animate={animate} transition={transition} {...properties}>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 549 614' className='size-full' fill='none'>
                <path
                    xmlns='http://www.w3.org/2000/svg'
                    d='M244.18 8.73771C262.789 -1.9932 285.715 -1.99319 304.323 8.73772L518.002 131.96C536.61 142.69 548.074 162.523 548.074 183.984L548.074 430.428C548.074 451.889 536.61 471.721 518.002 482.453L304.323 605.674C285.715 616.405 262.789 616.405 244.18 605.674L30.502 482.453C11.8936 471.721 0.431165 451.889 0.431166 430.428L0.431177 183.984C0.431178 162.523 11.8936 142.69 30.502 131.96L244.18 8.73771Z'
                    fill={`url(#${gradientId})`}
                />
                <defs>
                    <linearGradient id={gradientId} x1='548.074' y1='525.094' x2='-19.5824' y2='438.688' gradientUnits='userSpaceOnUse'>
                        <stop stopColor='currentColor' stopOpacity='0' />
                        <stop offset='1' stopColor='currentColor' />
                    </linearGradient>
                </defs>
            </svg>
        </motion.div>
    );
};

export default Hexagon;
